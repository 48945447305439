
import './index.less';
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        picAddress: {
            type: String,
            require: true
        }
    },
    setup(prop, { emit }) {
        const scale = ref(1.0);
        const changeScale = (multiple: number) => {
            scale.value = (scale.value >= 1.5 && multiple > 0)
                || (scale.value <= 1.0 && multiple < 0)
                ? scale.value : scale.value + multiple;
        };

        const imgClose = (e: MouseEvent) => {
            e.stopPropagation();
        };

        const close = () => {
            emit('close');
        };

        return {
            scale,
            changeScale,
            close,
            imgClose
        };
    }
});
