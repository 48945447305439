
import {
    defineComponent, PropType, watch, Ref, ref,
    computed, reactive
} from 'vue';
import { byteLength } from 'byte-length';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import { BreadCrumbData, DeliveryStaffFormData } from '@/views/pm/personnel/personnel-handle/all.type';
import HttpRequest from '@/util/axios.config';
import { personnel, community } from '@/data';
import Notice from '@/util/notice.base';
import accessGroupMode from '@/components/view/pm/access-group-model';
import { access } from '@/methods/rule';
import pinEncryptInput from '@/components/common/pin-encrypt-input/index.vue';
import ChooseFaceDialog from '@/views/pm/personnel/personnel-handle/choose-face-dialog.vue';
import { getCommunityBuild } from '@/data/src/community';
import { getOfficeDepartment } from '@/data/src/office';
import CusSelect from '@/components/common/customize-select/select.vue';
import CusButton from '@/components/common/customize-button/index.vue';
import CusOption from '@/components/common/customize-select/option.vue';

export default defineComponent({
    components: {
        CusOption,
        CusButton,
        CusSelect,
        propertyBreadCrumb,
        accessGroupMode,
        pinEncryptInput,
        ChooseFaceDialog
    },
    props: {
        type: {
            type: String as PropType<'deliveryAuth' | 'staffAuth'>,
            required: true
        },
        option: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        },
        optionUUID: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const breadCrumb: Ref<Array<BreadCrumbData>> = ref([
            {
                label: WordList.DeliveryDeliveryAuth,
                path: `/${propertyBaseRouter}/${propertySubRouter.personnel}?type=deliveryAuth`
            }, {
                label: WordList.ProperAllTextNew
            }
        ]);

        watch(computed(() => props.type), () => {
            if (props.type === 'deliveryAuth') {
                breadCrumb.value[0] = {
                    label: WordList.DeliveryDeliveryAuth,
                    path: `/${propertyBaseRouter}/${propertySubRouter.personnel}?type=deliveryAuth`
                };
            } else {
                breadCrumb.value[0] = {
                    label: WordList.DeliveryStaffAuth,
                    path: `/${propertyBaseRouter}/${propertySubRouter.personnel}?type=staffAuth`
                };
            }
        }, { immediate: true });

        // form相关操作
        const formRef: Ref<any> = ref();
        const formData: Ref<DeliveryStaffFormData> = ref({
            Name: '',
            PinCode: '',
            CardCode: '',
            AccessGroup: [],
            Build: [],
            Floor: [],
            HaveFace: false
        });

        const checkDeliveryAndStaffName: RuleMethod = (rule, value, callback) => {
            if (byteLength(value as string) > 63) {
                return callback(
                    new Error(
                        WordList.RuleLengthExceeds63.format(
                            WordList.TabelPersonUserInHtmlName,
                            WordList.TabelPersonUserInHtmlName
                        )
                    )
                );
            }
            return callback();
        };

        const rules = reactive({
            Name: [
                { required: true, message: WordList.RuleNameEmpty, trigger: 'blur' },
                { validator: checkDeliveryAndStaffName, trigger: 'blur' }
            ],
            CardCode: [
                // { required: props.type === 'staffAuth', message: WordList.RuleKeyEmpty, trigger: 'blur' },
                { validator: access.checkCard, trigger: 'blur' }
            ],
            PinCode: [
                {
                    validator: computed(() => access.checkPin(community.IsEncryptPin.value === '1', 'delivery', props.option)),
                    trigger: 'blur'
                }
            ]
        });

        // v6.5.2 楼层管理数据 zh 2022/12/9

        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: 'All Floors'
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }

        const floors = ref<{
            build: string;
            floor: string[];
            isShowNotice: boolean;
        }[]>([]);

        // 楼栋数据
        const buildOptions = ref<{key: string;value: string}[]>([]);

        function addFloors() {
            floors.value.push({
                build: '',
                floor: [],
                isShowNotice: false
            });
        }

        // 删除floor,转需求：11764
        function removeFloors(index: number) {
            floors.value.splice(index, 1);
        }

        // 最多只能选10个floor层
        function changeFloor(floor: string, index: number) {
            if (floors.value[index].floor.length > 10) {
                (floors.value[index].floor as string[]).pop();
            }
        }

        const selectItem: Ref<Array<string>> = ref([]);

        // 获取详情
        const getDetail = () => {
            personnel.getDeliveryOrDeliveryDetail(
                props.type === 'deliveryAuth'
                    ? `v3/web/${community.ProjectType.value}/delivery/getInfo`
                    : 'v3/web/community/staff/getInfo',
                { UUID: props.optionUUID },
                (res: DeliveryStaffFormData) => {
                    const param = { ...res };
                    param.PinCode = param.PinCode || '';
                    param.CardCode = param.CardCode || '';
                    formData.value = param;
                    selectItem.value = res.AccessGroup;
                    if (res.Build.length === 0) {
                        floors.value.push({
                            build: buildOptions.value.length === 1 ? buildOptions.value[0].key : '',
                            floor: [],
                            isShowNotice: false
                        });
                    } else {
                        (res.Build as string[]).forEach((build, index) => {
                            floors.value.push({
                                build,
                                floor: res.Floor[index].split(';'),
                                isShowNotice: false
                            });
                        });
                    }
                }
            );
        };
        watch(computed(() => props.option), () => {
            const apiFn = community.ProjectType.value === 'community' ? getCommunityBuild : getOfficeDepartment;
            apiFn((res: { data: {ID: string;UnitName: string}[] }) => {
                res.data.forEach((item) => {
                    buildOptions.value.push({
                        key: item.ID,
                        value: item.UnitName
                    });
                });
                if (props.option === 'add') {
                    floors.value.push({
                        build: buildOptions.value.length === 1 ? buildOptions.value[0].key : '',
                        floor: [],
                        isShowNotice: false
                    });
                } else {
                    getDetail();
                }
            });
            if (props.option === 'add') {
                breadCrumb.value[1] = {
                    label: WordList.ProperAllTextNew
                };
            } else {
                breadCrumb.value[1] = {
                    label: WordList.TabelConfigInHtmlModify
                };
            }
        }, { immediate: true });

        const getSelectValue = (ids: Array<string>) => {
            formData.value.AccessGroup = ids;
        };

        // v6.5.2 导入人脸操作 zh 2022/12/8
        const fileData: Ref<File | ''> = ref('');
        const isDeleteFace = ref('0');

        const chooseFile = (file: File) => {
            fileData.value = file;
        };
        const removeFile = () => {
            fileData.value = '';
            isDeleteFace.value = '1';
        };

        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;

                // v6.5.2检查数据当选择了Building时，Floor不能留空，若留空，保存时下方会出现提示语
                if (community.ProjectType.value === 'community') {
                    let floorsRule = false;
                    floors.value.forEach((floor, index) => {
                        if (floor.build && floor.floor.length === 0 && buildOptions.value.length !== 1) {
                            floors.value[index].isShowNotice = true;
                            floorsRule = true;
                        } else {
                            floors.value[index].isShowNotice = false;
                        }
                    });
                    if (floorsRule) {
                        return false;
                    }
                }
                let postUrl = '';
                const param: DeliveryStaffFormData = { ...formData.value };
                const staffParam = new FormData();

                // v6.5.2floor数据处理 转成Build:[build1,build2] Floor['floor1;floor2','floor1;floor2']
                const buildArr: string[] = [];
                const floorArr: string[] = [];

                floors.value.forEach((floor) => {
                    buildArr.push(floor.build);
                    floorArr.push(floor.floor.join(';'));
                });
                if (props.type === 'deliveryAuth') {
                    if (param.PinCode === '' && param.CardCode === '') {
                        Notice.messageBox('alert', WordList.ProperAllTextSetAccessType,
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return false;
                    }
                    if (param.AccessGroup.length === 0) {
                        Notice.messageBox('alert', WordList.ProperAllTextDelOrStaffAccessGroupTip,
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return false;
                    }

                    if (props.option === 'add') {
                        postUrl = `v3/web/${community.ProjectType.value}/delivery/add`;
                    } else {
                        postUrl = `v3/web/${community.ProjectType.value}/delivery/edit`;
                    }
                } else if (props.type === 'staffAuth') {
                    // 有无人脸的判断逻辑：原本有人脸被删除且未设置新值，或者原本没有人脸也没有设置新值
                    const noFace = (formData.value.HaveFace && isDeleteFace.value === '1' && fileData.value === '')
                      || (!formData.value.HaveFace && fileData.value === '');
                    // 6.7.0 检查pin,RFcard, face至少勾选一个
                    if (formData.value.CardCode === '' && noFace && formData.value.PinCode === '') {
                        Notice.messageBox('alert', WordList.ProperAllTextSetAccessType, WordList.PersonuserInfo, 'info')(() => false);
                        return false;
                    }

                    // 27164 判断逻辑错误，导致staff没选权限组也能添加成功
                    if (param.AccessGroup.length === 0) {
                        Notice.messageBox('alert', WordList.ProperAllTextDelOrStaffAccessGroupTip,
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return false;
                    }

                    staffParam.append('ID', (formData.value.ID as string));
                    staffParam.append('Name', formData.value.Name);
                    staffParam.append('CardCode', formData.value.CardCode as string);
                    // 6.7.0 Staff新增pin
                    staffParam.append('PinCode', formData.value.PinCode as string);
                    formData.value.AccessGroup.forEach((item, index) => {
                        staffParam.append(`AccessGroup[${index}]`, item);
                    });
                    if (fileData.value !== '') {
                        staffParam.append('Face', fileData.value);
                    }

                    // v6.5.2floor数据处理 转成Build:[build1,build2] Floor['floor1;floor2','floor1;floor2']
                    staffParam.append('Build', JSON.stringify(buildArr));
                    staffParam.append('Floor', JSON.stringify(floorArr));
                    if (props.option === 'add') {
                        postUrl = 'v3/web/community/staff/add';
                    } else {
                        postUrl = 'v3/web/community/staff/edit';
                        // 判断编辑时是否换个人脸
                        staffParam.append('IsDeleteFace', isDeleteFace.value);
                    }
                }
                HttpRequest.post(postUrl, props.type === 'deliveryAuth' ? {
                    ...param,
                    Build: JSON.stringify(buildArr),
                    Floor: JSON.stringify(floorArr)
                } : staffParam, () => {
                    router.push((breadCrumb.value[0].path) as string);
                });
                return true;
            });
        };

        return {
            breadCrumb,
            formRef,
            formData,
            submit,
            selectItem,
            rules,
            getSelectValue,
            projectType: community.ProjectType,
            chooseFile,
            removeFile,
            buildOptions,
            floorOptions,
            floors,
            addFloors,
            changeFloor,
            community,
            removeFloors
        };
    }
});
