import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.option==='add'||_ctx.isEncryptPin==='0')
      ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          type: _ctx.inputType,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
          autocomplete: "new-password"
        }, null, 8, ["type", "modelValue"]))
      : (_openBlock(), _createBlock(_component_el_input, {
          key: 1,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.value = $event)),
          type: _ctx.inputType,
          onBlur: _ctx.blur,
          onFocus: _ctx.focus,
          autocomplete: "new-password"
        }, null, 8, ["modelValue", "type", "onBlur", "onFocus"]))
  ]))
}