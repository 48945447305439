
import {
    computed, Ref, PropType,
    defineComponent, ref, watch
} from 'vue';
import { community } from '@/data';

export default defineComponent({
    name: 'pin-encrypt-input',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        option: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        }
    },
    setup(props, { emit }) {
        const inputType: Ref<'input'|'password'> = ref('input');
        watch(computed(() => community.IsEncryptPin.value), () => {
            inputType.value = community.IsEncryptPin.value === '1' ? 'password' : 'input';
        }, { immediate: true });

        const value = ref('');
        watch(computed(() => props.modelValue), () => {
            value.value = props.modelValue;
        }, {
            immediate: true
        });
        watch(value, () => {
            emit('update:modelValue', value.value);
        });

        const isEncryptPin = computed(() => community.IsEncryptPin.value);

        const blur = () => {
            if (value.value === '') {
                value.value = '****';
            }
        };
        const focus = () => {
            if (value.value === '****') {
                value.value = '';
            }
        };

        return {
            blur,
            focus,
            value,
            community,
            inputType,
            isEncryptPin
        };
    }
});
